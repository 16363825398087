import type { JSX } from "preact";
import classNames from "classnames";

import { SearchIcon } from "./SearchIcon";
import { type Ref } from "preact/compat";

import "./SearchInput.scss";
import type { Signal } from "@preact/signals";

type Props = {
  labelText: string;
  placeholdertext: string;
  searchRef?: Ref<HTMLInputElement>;
  id: string;
  className?: string;
  onSearchChange: (value: string) => void;
  onReset?: () => void;
  isValidInputText?: (inputText: string) => boolean;
  model: Signal<string>;
};

export const SearchInput = ({
  labelText,
  placeholdertext,
  isValidInputText,
  searchRef,
  id,
  className = "",
  onSearchChange,
  onReset,
  model,
}: Props) => {
  const handleSearchChange: JSX.InputEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.currentTarget;

    if (isValidInputText?.(value) === false) {
      return;
    }

    model.value = value;
    onSearchChange(e.currentTarget.value);
  };

  const handleReset = () => {
    model.value = "";
    onReset?.();
  };

  return (
    <div class={className}>
      <label
        htmlFor={id}
        className={classNames("search-input__label", {
          [className + "__label"]: !!className,
        })}
      >
        {labelText}
      </label>
      <div className="search-input__wrapper">
        <SearchIcon
          className={classNames("search-input__icon", {
            [className + "__icon"]: !!className,
          })}
        />
        <input
          className={classNames("search-input__input", {
            [className + "__input"]: !!className,
          })}
          id={id}
          data-value={model}
          type="search"
          value={model}
          onInput={handleSearchChange}
          autoComplete="off"
          placeholder={placeholdertext}
          ref={searchRef}
        />
        <button
          aria-label="Clear input text"
          className="search-input__button-clear"
          onClick={handleReset}
        ></button>
      </div>
    </div>
  );
};

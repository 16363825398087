import { useEffect, createRef } from "preact/compat";

export const useFocusVisible = <
  TRef extends HTMLElement,
  TDeps extends Array<unknown> = Array<unknown>,
>(
  dependencies: TDeps,
) => {
  const ref = createRef<TRef>();

  useEffect(() => {
    ref.current?.focus();
  }, dependencies);

  return ref;
};
